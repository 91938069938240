<template>
  <div>
    <b-card-actions
        ref="refreshCard"
        action-refresh
        action-create
        disable-loading-on-refresh
        @refresh="getItems"
        @create="hRedirect('faq-categories-new')"
        :loaded.sync="loaded"
    >
      <table-header :filter="filter" :per-page.sync="perPage" />

      <b-overlay :show="!loaded" rounded="sm">
        <b-table ref="refExpertsListTable" class="position-relative" responsive primary-key="id" :items="items"
                 :per-page="perPage" :current-page="currentPage" :fields="tableColumns" :sort-by.sync="sortBy"
                 :filter="filter.input" @filtered="onFiltered" show-empty :empty-text="$t('No matching records found')"
        >

          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-button :to="{
                            name: 'faq-categories-edit',
                            params: { id: data.item.id },
                        }" v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" type="submit"
                      size="sm"
            > {{
                $t('Edit')
              }}
            </b-button>
            <b-button v-on:click="deleteItem(data.item.id)" v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="danger" type="submit" size="sm"
            >
              {{ $t('Delete') }}
            </b-button>
          </template>
        </b-table>
      </b-overlay>

      <table-footer
          :current-page.sync="currentPage"
          :per-page="perPage"
          :length="totalRows" />
    </b-card-actions>
  </div>
</template>

<script>
import {
  BTable,
  BAvatar,
  BBadge,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCard,
  BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import moment from 'moment'
import TableHeader from '@/views/components/TableHeader'
import TableFooter from '@/views/components/TableFooter'
import { BCardActions } from '@core/components/b-card-actions'

export default {
  components: {
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCard,
    BOverlay,
    TableHeader,
    TableFooter,
    BCardActions,
    vSelect
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loaded: false,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: {
        input: null
      },
      tableColumns: [
        {
          key: 'position',
          sortable: true,
          label: this.$i18n.t('Pos.'),
        },
        {
          key: 'name',
          sortable: true,
          label: this.$i18n.t('Name'),
        },
        {
          key: 'area',
          sortable: true,
          label: this.$i18n.t('Area'),
        },
        {
          key: 'entries_count',
          sortable: true,
          label: this.$i18n.t('# Entries'),
        },
        {
          key: 'created_at',
          sortable: true,
          label: this.$i18n.t('Created at'),
          formatter: (value) => {
            return moment(String(value))
                .format('DD.MM.YYYY')
          }
        },
        {
          key: 'actions',
          label: this.$i18n.t('Actions'),
          class: 'table-action-buttons'
        }
      ],
      items: [],
    }
  },
  mounted() {
    this.getItems()
  },
  methods: {
    getItems() {
      this.helperGetItems(`/faq/categories`)
    },
    deleteItem(id) {
      this.helperDeleteItem(
          `/faq/categories/${id}`
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
</style>
